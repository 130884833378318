import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { RailsProvider } from '../../src/components/rails-provider';
import { StatusTable } from '../../src/components/status';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Note mdxType="Note">
      <p>{`Check out the `}<a parentName="p" {...{
          "href": "/guides/component-lifecycle"
        }}>{`component lifecycle`}</a>{` for more information on each status.`}</p>
    </Note>
    <RailsProvider mdxType="RailsProvider">
  <StatusTable mdxType="StatusTable" />
    </RailsProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      